import React from "react";

export default function Disclaimer() {
  return (
    <section className="mt-6">
      <h2 className="sr-only">Disclaimer</h2>
      <div className="p-4 pr-10 text-xxs sm:text-xs tracking-tight leading-snug text-gray-600">
        This information is furnished on a confidential basis exclusively for
        the use by you. By accepting this information you agree not to
        reproduce, redistribute or disclose this material, in whole or in part,
        to any other person or entity. Balyasny Asset Management L.P. (“BAM”)
        makes no warranty or representation with respect to the accuracy,
        validity or completeness of this information. Unless stated otherwise,
        the views, opinions and commentary expressed herein are subject to
        change without prior notice or any notice whatsoever. This is not an
        offer or solicitation with respect to the purchase or sale of any
        security. This information is not intended to be investment advice or
        investment recommendations. Nor should this information be used for
        investment decision making purposes and is not being included to assist
        in these decisions. Nothing herein is reflecting of BAM’s judgement ,
        position, or feeling son any investment or security. All information,
        infographics, or other info illustrative purposes only and cannot be
        used for investment decision making purposes and are not included to
        assist in such decisions.
      </div>
    </section>
  );
}
